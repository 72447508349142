import { Dictionary } from '@ngrx/entity';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { IndustrialSensorDefinedDataEnum, Products } from '@spog-ui/graphql/types';
import { BehaviorType, HIDDEN_GROUP } from '@spog-ui/shared/models/behaviors';
import {
  EquipmentTreeNode,
  sortDispositionChangesBySeverity,
  toEquipmentTreeNodeFromEquipment,
} from '@spog-ui/shared/models/equipment';
import {
  Bridge485MetricInternalModel,
  Bridge485SensorDetailsViewModel,
  getIndieSensorStatus,
  IndieSensorDetailsViewModel,
  IndieSensorInternalModel,
  IndieSensorMapViewModel,
  isBridge485Metric,
  isIndieSensorAlarmed,
  isIndustrialSensorDefinedDataType,
} from '@spog-ui/shared/models/indie-sensors';
import { LightZoneInternalModel } from '@spog-ui/shared/models/light-zones';
import {
  LightInternalModel,
  PositionedLightModel,
  toLightViewModel,
} from '@spog-ui/shared/models/lights';
import { LocationSettings } from '@spog-ui/shared/models/location';
import {
  DepartmentInternalModel,
  EquipmentInternalModel,
  isDepartment,
  isEquipment,
  isLegacyResourceGroup,
} from '@spog-ui/shared/models/resource-groups';
import {
  SceneZoneBehaviorViewModel,
  toLinkedBehaviorsFromSceneZoneBehaviors,
} from '@spog-ui/shared/models/scene-zone-behaviors';
import {
  LinkedBehavior,
  SceneApplication,
  SceneInternalModel,
  SceneStates,
  SceneViewModel,
} from '@spog-ui/shared/models/scenes';
import { toScheduledEventViewModelFromInternal } from '@spog-ui/shared/models/scheduled-events';
import {
  groupScheduledUtilityRatesByUtilityService,
  toScheduledUtilityRateViewModelFromInternal,
} from '@spog-ui/shared/models/scheduled-utility-rates';
import {
  SequenceSceneInternalModel,
  SequenceSceneStates,
  SequenceSceneViewModel,
  toSequenceSceneViewModelFromInternal,
} from '@spog-ui/shared/models/sequence-scenes';
import { toActiveSequenceSceneViewModelFromInternal } from '@spog-ui/shared/models/active-sequence-scenes';
import {
  SiteControllerInternalModel,
  isSiteControllerHavingConnectivityIssues,
  toSiteControllerViewModelFromInternal,
} from '@spog-ui/shared/models/site-controllers';
import {
  productsToDictionary,
  toSiteDetailsViewModelFromInternal,
} from '@spog-ui/shared/models/sites';
import {
  toUtilityServiceViewModelFromInternal,
  UtilityServiceInternalModel,
} from '@spog-ui/shared/models/utility-services';
import { ZoneApplication, ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { RouterStateUrl } from '@spog-ui/utils/router';
import { groupBy, values, orderBy } from 'lodash';
import { DateTime, IANAZone } from 'luxon';
import * as ActiveSequenceScenesState from './active-sequence-scenes';
import * as AlarmsState from './alarms';
import * as AppUpdatesState from './app-updates';
import * as ApplySceneStatusState from './apply-scene-status';
import * as ApplySequenceSceneStatusState from './apply-sequence-scene-status';
import * as BehaviorState from './behavior';
import * as BrowserState from './browser';
import * as ClockState from './clock';
import * as ControlZonesState from './control-zones';
import * as ControllersState from './controllers';
import * as EulaState from './eula';
import * as IndieSensorsState from './indie-sensors';
import * as LightZonesState from './light-zone';
import * as LightsState from './lights';
import { localStorageSyncMetaReducer } from './local-storage-sync.metareducer';
import * as MapState from './map';
import { mapPositioningMetaReducer } from './map-positioning.metareducer';
import * as MenuState from './menu';
import * as MetricHistoryState from './metric-history';
import * as PageState from './page';
import * as RedirectState from './redirect';
import * as ResourceGroupsState from './resource-groups';
import * as SceneViewsState from './scene-views.reducer';
import * as ScenesState from './scenes';
import * as ScheduledEventsState from './scheduled-events';
import * as ScheduledUtilityRatesState from './scheduled-utility-rates';
import * as SequenceScenesState from './sequence-scenes';
import * as SiteState from './site';
import * as SiteControllersState from './site-controllers';
import * as SiteSelectorState from './site-selector';
import * as ThermostatAlarmsState from './thermostat-alarms';
import * as ThermostatsState from './thermostats';
import * as TriggersState from './triggers';
import * as UtilityServicesState from './utility-services';
import { zoneControlMetaReducer } from './zone-control.metareducer';

export const ZONE_ALL_ID = 'id_builtin_zone_all';

export const metaReducers = [
  localStorageSyncMetaReducer,
  zoneControlMetaReducer,
  mapPositioningMetaReducer,
];

export const STATE_KEY = 'core';

export interface Shape {
  activeSequenceScenes: ActiveSequenceScenesState.Shape;
  alarms: AlarmsState.Shape;
  applySceneStatus: ApplySceneStatusState.Shape;
  applySequenceSceneStatus: ApplySequenceSceneStatusState.Shape;
  appUpdates: AppUpdatesState.Shape;
  behaviors: BehaviorState.Shape;
  browser: BrowserState.Shape;
  clock: ClockState.Shape;
  controllers: ControllersState.Shape;
  eula: EulaState.Shape;
  indieSensors: IndieSensorsState.Shape;
  lights: LightsState.Shape;
  lightZones: LightZonesState.Shape;
  map: MapState.Shape;
  menu: MenuState.Shape;
  metricHistory: MetricHistoryState.Shape;
  page: PageState.Shape;
  redirect: RedirectState.Shape;
  resourceGroups: ResourceGroupsState.Shape;
  router: RouterReducerState<RouterStateUrl>;
  sceneViews: SceneViewsState.Shape;
  scenes: ScenesState.Shape;
  scheduledEvents: ScheduledEventsState.Shape;
  scheduledUtilityRate: ScheduledUtilityRatesState.Shape;
  sequenceScenes: SequenceScenesState.Shape;
  site: SiteState.Shape;
  siteControllers: SiteControllersState.Shape;
  siteSelector: SiteSelectorState.Shape;
  thermostats: ThermostatsState.Shape;
  thermostatAlarms: ThermostatAlarmsState.Shape;
  triggers: TriggersState.Shape;
  utilityServices: UtilityServicesState.Shape;
  controlZones: ControlZonesState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  activeSequenceScenes: ActiveSequenceScenesState.reducer,
  alarms: AlarmsState.reducer,
  applySceneStatus: ApplySceneStatusState.reducer,
  applySequenceSceneStatus: ApplySequenceSceneStatusState.reducer,
  appUpdates: AppUpdatesState.reducer,
  behaviors: BehaviorState.reducer,
  browser: BrowserState.reducer,
  clock: ClockState.reducer,
  controllers: ControllersState.reducer,
  eula: EulaState.reducer,
  indieSensors: IndieSensorsState.reducer,
  lights: LightsState.reducer,
  lightZones: LightZonesState.reducer,
  map: MapState.reducer,
  menu: MenuState.reducer,
  metricHistory: MetricHistoryState.reducer,
  page: PageState.reducer,
  redirect: RedirectState.reducer,
  resourceGroups: ResourceGroupsState.reducer,
  router: routerReducer,
  sceneViews: SceneViewsState.reducer,
  scenes: ScenesState.reducer,
  scheduledEvents: ScheduledEventsState.reducer,
  scheduledUtilityRate: ScheduledUtilityRatesState.reducer,
  sequenceScenes: SequenceScenesState.reducer,
  site: SiteState.reducer,
  siteControllers: SiteControllersState.reducer,
  siteSelector: SiteSelectorState.reducer,
  thermostats: ThermostatsState.reducer,
  thermostatAlarms: ThermostatAlarmsState.reducer,
  triggers: TriggersState.reducer,
  utilityServices: UtilityServicesState.reducer,
  controlZones: ControlZonesState.reducer,
};

export function sortByName<T extends { name: string }>(a: T, b: T): number {
  return a.name.localeCompare(b.name);
}

export const selectCoreFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Apply Scenes Status State
 */
export const selectApplyScenesStatusState = createSelector(
  selectCoreFeatureState,
  state => state.applySceneStatus,
);

/**
 * Apply Sequence Scenes Status State
 */
export const selectApplySequenceScenesStatusState = createSelector(
  selectCoreFeatureState,
  state => state.applySequenceSceneStatus,
);

/**
 * App Updates State
 */
export const selectAppUpdatesState = createSelector(
  selectCoreFeatureState,
  state => state.appUpdates,
);
export const selectIsCheckingForUpdate = createSelector(
  selectAppUpdatesState,
  AppUpdatesState.selectIsCheckingForUpdate,
);
export const selectIsUpdateAvailable = createSelector(
  selectAppUpdatesState,
  AppUpdatesState.selectIsUpdateAvailable,
);
export const selectIsDownloadingUpdate = createSelector(
  selectAppUpdatesState,
  AppUpdatesState.selectIsDownloadingUpdate,
);
export const selectIsUpdateDownloaded = createSelector(
  selectAppUpdatesState,
  AppUpdatesState.selectIsUpdateDownloaded,
);
export const selectShowCheckForUpdates = createSelector(
  selectIsCheckingForUpdate,
  selectIsUpdateAvailable,
  selectIsDownloadingUpdate,
  selectIsUpdateDownloaded,
  (isCheckingForUpdate, isUpdateAvailable, isDownloadingUpdate, isUpdateDownloaded) => {
    return !(
      isCheckingForUpdate ||
      isUpdateAvailable ||
      isDownloadingUpdate ||
      isUpdateDownloaded
    );
  },
);

/**
 * Router State
 */
export const selectRouterState = createSelector(
  selectCoreFeatureState,
  state => state.router?.state as RouterStateUrl | null,
);
export const selectRouterParams = createSelector(
  selectRouterState,
  state => state?.params ?? {},
);
export const selectRouterQueryParams = createSelector(
  selectRouterState,
  state => state?.queryParams ?? {},
);
export const selectRouterData = createSelector(
  selectRouterState,
  state => state?.routeData ?? {},
);
export const selectRouterUrl = createSelector(selectRouterState, state => state?.url);

export const selectNotOnMapSite = createSelector(
  selectRouterUrl,
  url => !url || !url.includes('/site-map'),
);

// Return the current url
export const selectUrlBeforeDetails = createSelector(selectRouterUrl, url => {
  if (!url) {
    return '';
  }
  const detailsIndex = url.lastIndexOf('/details');

  if (detailsIndex === -1) {
    // if no details is present, return URL as is
    return url;
  }

  return url.slice(0, detailsIndex);
});

/**
 * Active Sequence Scenes
 */
export const selectActiveSequenceScenesState = createSelector(
  selectCoreFeatureState,
  state => state.activeSequenceScenes,
);
export const selectAllActiveSequenceScenes = createSelector(
  selectActiveSequenceScenesState,
  ActiveSequenceScenesState.selectAll,
);

/**
 * Alarms
 */
export const selectAlarmsState = createSelector(
  selectCoreFeatureState,
  state => state.alarms,
);
export const selectAllAlarms = createSelector(selectAlarmsState, AlarmsState.selectAll);

export const selectHasAlarms = createSelector(
  selectAllAlarms,
  allAlarms => allAlarms.length > 0,
);

export const selectTotalAlarms = createSelector(
  selectAllAlarms,
  allAlarms => allAlarms.length,
);

export const selectAlarmLookupTableByControllerId = createSelector(
  selectAlarmsState,
  AlarmsState.selectAlarmsByControllerId,
);

/**
 * Behaviors State
 */
export const selectBehaviorsState = createSelector(
  selectCoreFeatureState,
  state => state.behaviors,
);
export const selectAllBehaviors = createSelector(
  selectBehaviorsState,
  BehaviorState.selectAll,
);

export const selectAllVisibleBehaviors = createSelector(selectAllBehaviors, behaviors =>
  behaviors.filter(behavior => behavior.displayGroup !== HIDDEN_GROUP),
);

export const selectAllNonDLHBehaviors = createSelector(
  selectAllVisibleBehaviors,
  allBehaviors => allBehaviors.filter(behavior => behavior.id !== BehaviorType.DLH),
);

/**
 * Browser State
 */
export const selectBrowserState = createSelector(
  selectCoreFeatureState,
  state => state.browser,
);

export const selectBrowserSize = createSelector(
  selectBrowserState,
  BrowserState.selectSize,
);

export const selectBrowserIsOnline = createSelector(
  selectBrowserState,
  BrowserState.selectIsOnline,
);

export const selectBrowserIsOffline = createSelector(
  selectBrowserState,
  BrowserState.selectIsOffline,
);

/**
 * Clock State
 */
export const selectClockState = createSelector(
  selectCoreFeatureState,
  state => state.clock,
);

export const selectTimeString = createSelector(
  selectClockState,
  ClockState.selectTimeString,
);

/**
 * Controllers
 */
export const selectControllersState = createSelector(
  selectCoreFeatureState,
  state => state.controllers,
);
export const selectControllerIds = createSelector(
  selectControllersState,
  ControllersState.selectIds,
);
export const selectControllerEntities = createSelector(
  selectControllersState,
  ControllersState.selectEntities,
);
export const selectAllControllers = createSelector(
  selectControllersState,
  ControllersState.selectAll,
);

/**
 * Eula State
 */
export const selectEulaState = createSelector(
  selectCoreFeatureState,
  state => state.eula,
);
export const selectEulaAccepted = createSelector(
  selectEulaState,
  EulaState.selectEulaStatus,
);
export const selectEulaError = createSelector(selectEulaState, EulaState.selectError);

/**
 * URL
 */
export const selectActiveSiteId = createSelector(
  selectRouterParams,
  params => params['activeSiteId'] as string | undefined | null,
);
export const selectSiteURLPrefix = createSelector(selectActiveSiteId, siteId => {
  return siteId ? `/site/${siteId}` : '';
});

/**
 * Site
 */
export const selectSiteState = createSelector(
  selectCoreFeatureState,
  state => state.site,
);
export const selectSiteIds = createSelector(selectSiteState, SiteState.selectIds);
export const selectSiteEntities = createSelector(
  selectSiteState,
  SiteState.selectEntities,
);
export const selectAllSites = createSelector(selectSiteState, SiteState.selectAll);
export const selectTotalSites = createSelector(selectSiteState, SiteState.selectTotal);
export const selectHasSites = createSelector(selectTotalSites, total => total > 0);
export const selectHasNoSites = createSelector(selectTotalSites, total => total == 0);

/**
 * Site Selector
 */
export const selectSiteSelectorState = createSelector(
  selectCoreFeatureState,
  state => state.siteSelector,
);
export const selectSitesLoading = createSelector(
  selectSiteSelectorState,
  state => state.sitesLoading,
);
export const selectSelectedSiteId = createSelector(
  selectSiteSelectorState,
  SiteSelectorState.selectSelectedSiteId,
);
export const selectSitesForSiteSelector = createSelector(
  selectAllSites,
  selectSelectedSiteId,
  (allSites, selectedSiteId) => allSites.filter(site => site.id !== selectedSiteId),
);

export const selectSelectedSite = createSelector(
  selectSelectedSiteId,
  selectSiteEntities,
  (id, entities) => (id === null ? null : entities[id]),
);
export const selectActiveSiteIdDiffersFromSelectedSite = createSelector(
  selectActiveSiteId,
  selectSelectedSite,
  (activeSiteId, selectedSite) => !selectedSite || selectedSite.id !== activeSiteId,
);
export const selectSiteForActiveSiteId = createSelector(
  selectActiveSiteId,
  selectSiteEntities,
  (id, entities) => {
    return id == null ? null : entities[id];
  },
);

export const selectActiveSiteSubscriptionInfo = createSelector(
  selectSiteForActiveSiteId,
  activeSite => activeSite?.subscriptionInfo,
);

export const selectActiveSiteFeaturesRestModel = createSelector(
  selectSiteForActiveSiteId,
  activeSite => productsToDictionary(activeSite?.enabledProducts ?? []),
);

export const selectSelectedSiteTimeZone = createSelector(selectSelectedSite, site =>
  site && site.timeZone ? site.timeZone : 'local',
);

export const selectSiteTime = createSelector(
  selectTimeString,
  selectSelectedSiteTimeZone,
  (time, zone) => DateTime.fromISO(time, { zone }),
);

export const selectSelectedSiteLocationSettings = createSelector(
  selectSelectedSite,
  (site): LocationSettings => {
    if (site && site.timeZone && site.geoLocation) {
      return {
        timeZone: new IANAZone(site.timeZone),
        latitude: site.geoLocation.latitude,
        longitude: site.geoLocation.longitude,
      };
    }
    //Fallback to Synapse's zone/lat/long.
    return {
      timeZone: new IANAZone('America/Chicago'),
      latitude: 34.721133,
      longitude: -86.674988,
    };
  },
);

export const selectSelectedSiteProducts = createSelector(selectSelectedSite, site =>
  site ? site.enabledProducts : [],
);

export const selectHasMultipleMapProductsEnabled = createSelector(
  selectSelectedSiteProducts,
  enabledProducts =>
    // SENSE includes the Equipment and Sense map layers
    enabledProducts.includes(Products.SENSE) ||
    enabledProducts.filter(product =>
      [Products.ILLUMINATE, Products.CLIMATE].includes(product),
    ).length > 1,
);

/**
 * Site Controllers
 */
export const selectSiteControllersState = createSelector(
  selectCoreFeatureState,
  state => state.siteControllers,
);
export const selectAllSiteControllers = createSelector(
  selectSiteControllersState,
  SiteControllersState.selectAll,
);

export const selectSiteControllerEntities = createSelector(
  selectSiteControllersState,
  SiteControllersState.selectEntities,
);

export const selectAllSiteControllerViews = createSelector(
  selectAllSiteControllers,
  selectAllSites,
  (siteControllers, sites) =>
    siteControllers.map(siteController => {
      const matchingSite = sites.find(site => site.id === siteController.siteId);

      return toSiteControllerViewModelFromInternal(siteController, matchingSite);
    }),
);

export const selectSiteControllerViewsForSelectedSite = createSelector(
  selectAllSiteControllers,
  selectSelectedSite,
  (siteControllers, site) =>
    siteControllers
      .filter(siteController => site && siteController.siteId === site.id)
      .map(siteController => toSiteControllerViewModelFromInternal(siteController, site)),
);

export const selectIfRemoteAccessUpForAllSiteControllersInSelectedSite = createSelector(
  selectSiteControllerViewsForSelectedSite,
  siteControllers =>
    siteControllers.every(siteController => {
      const remoteAccessResources = siteController.resources.filter(
        resource => resource.type === 'REMOTE_ACCESS',
      );

      if (remoteAccessResources.length === 0) {
        console.log('no remote access');
        return false;
      }

      if (['DOWN', 'UNKNOWN', 'INIT_FAILED'].includes(remoteAccessResources[0].status)) {
        console.log('remote access is down');
        return false;
      }

      console.log('remote access is up');
      return true;
    }),
);

export const selectTotalOfflineSiteControllers = createSelector(
  selectSiteControllerViewsForSelectedSite,
  allSiteControllers =>
    allSiteControllers.filter(siteController =>
      isSiteControllerHavingConnectivityIssues(siteController),
    ).length,
);

export const selectSiteDetailsViewsForSiteMap = createSelector(
  selectAllSites,
  selectAllSiteControllerViews,
  (sites, siteControllerViews) => {
    return sites.map(site => {
      const matchingSiteControllers = siteControllerViews.filter(
        siteControllerView => siteControllerView.siteId === site.id,
      );

      return toSiteDetailsViewModelFromInternal(site, matchingSiteControllers);
    });
  },
);

/**
 * Indie Sensors
 */
export const selectIndieSensorsState = createSelector(
  selectCoreFeatureState,
  state => state.indieSensors,
);

export const selectAllIndieSensors = createSelector(
  selectIndieSensorsState,
  IndieSensorsState.selectAll,
);

export const hasAllIndieSensors = createSelector(
  selectAllIndieSensors,
  indieSensors => indieSensors && indieSensors.length > 0,
);

export const selectTotalNumberOfIndieSensors = createSelector(
  selectIndieSensorsState,
  IndieSensorsState.selectTotal,
);

export const selectIndieSensorEntities = createSelector(
  selectIndieSensorsState,
  IndieSensorsState.selectEntities,
);

export const isPowerSensor = (
  indieSensor: IndieSensorInternalModel | IndieSensorDetailsViewModel,
) =>
  isIndustrialSensorDefinedDataType(indieSensor.dataType) &&
  indieSensor.dataType.type === IndustrialSensorDefinedDataEnum.POWER;

export const selectAllPowerSensors = createSelector(selectAllIndieSensors, indieSensors =>
  indieSensors.filter(isPowerSensor),
);

export const selectActiveIndieSensorId = createSelector(
  selectRouterParams,
  params => params['activeIndieSensorId'] as string | undefined | null,
);

/**
 * Lights
 */
export const selectLightsState = createSelector(
  selectCoreFeatureState,
  state => state.lights,
);
export const selectLightIds = createSelector(selectLightsState, LightsState.selectIds);
export const selectLightEntities = createSelector(
  selectLightsState,
  LightsState.selectEntities,
);
export const selectAllLights = createSelector(selectLightsState, LightsState.selectAll);
export const selectTotalLights = createSelector(
  selectLightsState,
  LightsState.selectTotal,
);
export const selectPositionedLights = createSelector(selectAllLights, lights =>
  lights.filter(light => light.floorPlanX !== null && light.floorPlanY !== null),
);

/**
 * Map State
 */
export const selectMapState = createSelector(selectCoreFeatureState, state => state.map);
export const selectIsMapLoadingEssentialModels = createSelector(
  selectMapState,
  MapState.selectIsLoadingEssentialModels,
);
export const selectMapHasEssentialModelsError = createSelector(
  selectMapState,
  MapState.selectHasEssentialModelsError,
);
export const selectMapHasEssentialModelsNetworkError = createSelector(
  selectMapState,
  MapState.selectHasEssentialModelsNetworkError,
);

export const selectShowMapFilter = createSelector(
  selectMapState,
  MapState.selectShowMapFilter,
);

export const selectToggleMapSearch = createSelector(
  selectMapState,
  MapState.selectToggleMapSearch,
);

export const selectLightMapLayerIconSize = createSelector(
  selectMapState,
  MapState.selectLightMapLayerIconSize,
);

/**
 * Menu State
 */
export const selectMenuState = createSelector(
  selectCoreFeatureState,
  state => state.menu,
);
export const selectMenuIsOpen = createSelector(selectMenuState, MenuState.selectIsOpen);

/**
 * Page State
 */
export const selectPageState = createSelector(
  selectCoreFeatureState,
  state => state.page,
);
export const selectPageProduct = createSelector(selectPageState, PageState.selectProduct);
export const selectPageName = createSelector(selectPageState, PageState.selectName);
export const selectPageIsBeta = createSelector(selectPageState, PageState.selectIsBeta);

/**
 * Redirect State
 */
export const selectRedirectState = createSelector(
  selectCoreFeatureState,
  state => state.redirect,
);

export const selectAdminPanelRedirectUrl = createSelector(
  selectRedirectState,
  state => state.adminPanelRedirectUrl,
);

export const selectSiteControllerRedirectUrl = createSelector(
  selectRedirectState,
  state => state.siteControllerRedirectUrl,
);

/**
 * Resource Groups State
 */
export const selectResourceGroupsState = createSelector(
  selectCoreFeatureState,
  state => state.resourceGroups,
);
export const selectAllResourceGroups = createSelector(
  selectResourceGroupsState,
  ResourceGroupsState.selectAll,
);

export const hasAllResourceGroups = createSelector(
  selectAllResourceGroups,
  resourceGroups => resourceGroups && resourceGroups.length > 0,
);

/**
 * Departments
 */

export const selectAllDepartments = createSelector(
  selectAllResourceGroups,
  resourceGroups => resourceGroups.filter(isDepartment),
);

export const selectAllNonDepartmentResourceGroups = createSelector(
  selectAllResourceGroups,
  resourceGroups => resourceGroups.filter(resourceGroup => !isDepartment(resourceGroup)),
);

/**
 * Equipment
 */
export const selectAllEquipment = createSelector(
  selectAllResourceGroups,
  resourceGroups => resourceGroups.filter(isEquipment),
);

export const selectNonchildEquipment = createSelector(selectAllEquipment, equipment => {
  const childEquipmentIds = new Set<string>();

  equipment.forEach(pieceOfEquipment => {
    pieceOfEquipment.resourceGroupIds.forEach(id => childEquipmentIds.add(id));
  });

  return equipment.filter(piece => !childEquipmentIds.has(piece.id));
});

export const selectActiveEquipmentId = createSelector(
  selectRouterParams,
  params => params['activeEquipmentId'] as string | undefined | null,
);

export const selectActiveEquipment = createSelector(
  selectAllEquipment,
  selectActiveEquipmentId,
  (equipment: EquipmentInternalModel[], id: string | undefined | null) => {
    let activeEquipment = equipment.find(e => e.id === id);

    //  Sort equipment by severity
    if (
      activeEquipment &&
      activeEquipment.dispositionChanges &&
      activeEquipment.dispositionChanges.length > 0
    ) {
      activeEquipment = {
        ...activeEquipment,
        dispositionChanges: sortDispositionChangesBySeverity(
          activeEquipment.dispositionChanges,
        ),
      };
    }
    return activeEquipment;
  },
);

export const selectActiveEquipmentParent = createSelector(
  selectAllEquipment,
  selectActiveEquipmentId,
  (
    allEquipment: EquipmentInternalModel[],
    activeEquipmentId: string | undefined | null,
  ) => {
    if (!activeEquipmentId) {
      return;
    }
    return getParentEquipmentByChildId(allEquipment, activeEquipmentId);
  },
);

export const selectAllActiveEquipmentChildrenTreeNodes = createSelector(
  selectAllEquipment,
  selectActiveEquipment,
  (
    allEquipment: EquipmentInternalModel[],
    activeEquipment: EquipmentInternalModel | undefined,
  ) => {
    return getChildEquipmentTreeNodesForEquipment(allEquipment, activeEquipment);
  },
);

export const selectActiveIndieSensorEquipment = createSelector(
  selectActiveIndieSensorId,
  selectAllEquipment,
  (activeIndieSensorId, allEquipment) => {
    if (!activeIndieSensorId) {
      return undefined;
    }

    // assumption: every industrial sensor is associated with at maximum one piece of equipment
    return allEquipment.find(equipment =>
      equipment.industrialSensorIds.includes(activeIndieSensorId),
    );
  },
);

// A method to Filter out undefined from an array in a way that
// doesn't confuse Typescript.
const isEquipmentTreeNode = (
  node: EquipmentTreeNode | undefined,
): node is EquipmentTreeNode => {
  return !!node;
};

function getChildEquipmentTreeNodesForEquipment(
  allEquipment: EquipmentInternalModel[],
  pieceOfEquipment?: EquipmentInternalModel,
): EquipmentTreeNode[] {
  if (pieceOfEquipment === undefined) return [];

  if (
    pieceOfEquipment.resourceGroupIds !== undefined &&
    pieceOfEquipment.resourceGroupIds.length > 0
  ) {
    const childEquipmentTreeNodes = pieceOfEquipment.resourceGroupIds
      .map(rgId => {
        // Get the child equipment referenced by the ID
        const childEquipment = allEquipment.find(eq => eq.id === rgId);
        if (childEquipment === undefined) return;
        const childEquipmentTreeNode = toEquipmentTreeNodeFromEquipment(childEquipment);

        // Interrogate the equipment's ResourceGroupIDs and repeat this function.
        if (
          childEquipment.resourceGroupIds !== undefined &&
          childEquipment.resourceGroupIds.length > 0
        ) {
          childEquipmentTreeNode.children = getChildEquipmentTreeNodesForEquipment(
            allEquipment,
            childEquipment,
          );

          childEquipmentTreeNode.disposition = childEquipment.disposition.level;
          childEquipmentTreeNode.runningState = childEquipment.runningState.state;
        }

        return childEquipmentTreeNode;
      })
      .filter(isEquipmentTreeNode);

    return childEquipmentTreeNodes;
  } else {
    return [];
  }
}

function getParentEquipmentByChildId(
  allEquipment: EquipmentInternalModel[],
  equipmentId: string,
) {
  return allEquipment.find(equipment =>
    equipment.resourceGroupIds.some(rgId => rgId === equipmentId),
  );
}

export const selectActiveEquipmentIndustrialSensors = createSelector(
  selectAllIndieSensors,
  selectActiveEquipment,
  (indieSensors, pieceOfEquipment) => {
    return indieSensors.filter(indieSensor =>
      pieceOfEquipment?.industrialSensorIds.some(id => indieSensor.id === id),
    );
  },
);

export const selectActiveEquipmentDepartments = createSelector(
  selectActiveEquipmentId,
  selectAllDepartments,
  (equipmentId: string | null | undefined, departments: DepartmentInternalModel[]) =>
    departments.filter(department =>
      department.resourceGroupIds.includes(equipmentId ?? ''),
    ),
);

/**
 * Legacy Resource Groups
 */
export const selectAllLegacyResourceGroups = createSelector(
  selectAllResourceGroups,
  resourceGroups => resourceGroups.filter(isLegacyResourceGroup),
);

/**
 * Scene Views State
 */
export const selectSceneViewsState = createSelector(
  selectCoreFeatureState,
  state => state.sceneViews,
);
export const selectSceneViewIds = createSelector(
  selectSceneViewsState,
  SceneViewsState.selectIds,
);
export const selectSceneViewEntities = createSelector(
  selectSceneViewsState,
  SceneViewsState.selectEntities,
);
export const selectAllSceneViews = createSelector(
  selectSceneViewsState,
  SceneViewsState.selectAll,
);

/**
 * Scenes State
 */
export const selectScenesState = createSelector(
  selectCoreFeatureState,
  state => state.scenes,
);
export const selectSceneIds = createSelector(selectScenesState, ScenesState.selectIds);
export const selectSceneEntities = createSelector(
  selectScenesState,
  ScenesState.selectEntities,
);
export const selectAllScenes = createSelector(selectScenesState, ScenesState.selectAll);
export const selectHasScenes = createSelector(
  selectAllScenes,
  scenes => scenes.length > 0,
);

export const selectAllClimateScenes = createSelector(selectAllScenes, scenes =>
  scenes.filter(scene => scene.application === SceneApplication.CLIMATE),
);

/**
 * Sequence Scenes State
 */
export const selectSequenceScenesState = createSelector(
  selectCoreFeatureState,
  state => state.sequenceScenes,
);
export const selectSequenceSceneIds = createSelector(
  selectSequenceScenesState,
  SequenceScenesState.selectIds,
);
export const selectSequenceSceneEntities = createSelector(
  selectSequenceScenesState,
  SequenceScenesState.selectEntities,
);
export const selectAllSequenceScenes = createSelector(
  selectSequenceScenesState,
  SequenceScenesState.selectAll,
);
export const selectHasSequenceScenes = createSelector(
  selectAllSequenceScenes,
  sequenceScenes => sequenceScenes.length > 0,
);

export const selectSequenceSceneViews = createSelector(
  selectAllSequenceScenes,
  selectAllScenes,
  selectApplySequenceScenesStatusState,
  (
    sequenceScenes: SequenceSceneInternalModel[],
    scenes: SceneInternalModel[],
    statuses: { [sequenceSceneId: string]: SequenceSceneStates },
  ): SequenceSceneViewModel[] =>
    sequenceScenes.map<SequenceSceneViewModel>(sequenceScene =>
      toSequenceSceneViewModelFromInternal(
        sequenceScene,
        scenes,
        statuses[sequenceScene.id] || 'ready',
      ),
    ),
);

/**
 * Scene Zone Behaviors State
 */
export const selectAllSceneZoneBehaviors = createSelector(selectAllScenes, allScenes => {
  return allScenes.flatMap(scene => {
    return [
      ...scene.zoneBehaviors.map(zoneBehavior => ({
        sceneId: scene.id,
        // TODO @electrichead Remove this (deprecated) field downstream and remove here
        id: `${scene.id}-${zoneBehavior.zoneId}-${zoneBehavior.behaviorId}`,
        ...zoneBehavior,
      })),
    ];
  });
});

/**
 * Scheduled Events State
 */
export const selectScheduledEventsState = createSelector(
  selectCoreFeatureState,
  state => state.scheduledEvents,
);
export const selectScheduledEventIds = createSelector(
  selectScenesState,
  ScenesState.selectIds,
);
export const selectScheduledEventEntities = createSelector(
  selectScheduledEventsState,
  ScheduledEventsState.selectEntities,
);
export const selectAllScheduledEvents = createSelector(
  selectScheduledEventsState,
  ScheduledEventsState.selectAll,
);

/**
 * Scheduled Utility Rates
 */
export const selectScheduledUtilityRatesState = createSelector(
  selectCoreFeatureState,
  state => state.scheduledUtilityRate,
);
export const selectAllScheduledUtilityRates = createSelector(
  selectScheduledUtilityRatesState,
  ScheduledUtilityRatesState.selectAll,
);
export const selectScheduledUtilityRateEntities = createSelector(
  selectScheduledUtilityRatesState,
  ScheduledUtilityRatesState.selectEntities,
);
export const selectScheduledUtilityRateViews = createSelector(
  selectAllScheduledUtilityRates,
  selectSelectedSiteTimeZone,
  (scheduledUtilityRates, siteTimeZone) => {
    return scheduledUtilityRates.map(scheduledUtilityRate => {
      return toScheduledUtilityRateViewModelFromInternal(
        scheduledUtilityRate,
        siteTimeZone,
      );
    });
  },
);
export const selectScheduledUtilityRateViewsGroupedByUtilityService = createSelector(
  selectScheduledUtilityRateViews,
  viewModels => groupScheduledUtilityRatesByUtilityService(viewModels),
);

/**
 * Thermostats
 */
export const selectThermostatsState = createSelector(
  selectCoreFeatureState,
  state => state.thermostats,
);
export const selectThermostatsIds = createSelector(
  selectThermostatsState,
  ThermostatsState.selectIds,
);
export const selectThermostatsEntities = createSelector(
  selectThermostatsState,
  ThermostatsState.selectEntities,
);
export const selectAllThermostats = createSelector(
  selectThermostatsState,
  ThermostatsState.selectAll,
);

/**
 * Thermostat Alarms
 */
export const selectThermostatAlarmsState = createSelector(
  selectCoreFeatureState,
  state => state.thermostatAlarms,
);

export const selectThermostatAlarmIds = createSelector(
  selectThermostatAlarmsState,
  ThermostatAlarmsState.selectIds,
);

export const selectThermostatAlarmEntities = createSelector(
  selectThermostatAlarmsState,
  ThermostatAlarmsState.selectEntities,
);

export const selectAllThermostatAlarms = createSelector(
  selectThermostatAlarmsState,
  ThermostatAlarmsState.selectAll,
);

export const selectTotalThermostatAlarms = createSelector(
  selectAllThermostatAlarms,
  thermostatAlarms => thermostatAlarms.length,
);

export const selectThermostatAlarmTypeByThermostatId = createSelector(
  selectThermostatAlarmsState,
  ThermostatAlarmsState.selectThermostatAlarmTypeByThermostatId,
);

export const selectThermostatAlarmsLookupTableByThermostatId = createSelector(
  selectThermostatAlarmsState,
  ThermostatAlarmsState.selectThermostatAlarmsLookupTableByThermostatId,
);

/**
 * Triggers
 */
export const selectTriggersState = createSelector(
  selectCoreFeatureState,
  state => state.triggers,
);
export const selectAllTriggers = createSelector(
  selectTriggersState,
  TriggersState.selectAll,
);
export const hasAllTriggers = createSelector(
  selectAllTriggers,
  triggers => triggers && triggers.length > 0,
);
export const selectAllMetricSources = createSelector(
  selectAllIndieSensors,
  indieSensors => [...indieSensors],
);
export const selectMetricHistoryState = createSelector(
  selectCoreFeatureState,
  state => state.metricHistory,
);
export const selectAllMetricHistories = createSelector(
  selectMetricHistoryState,
  MetricHistoryState.selectAll,
);
export const selectMetricHistoryEntities = createSelector(
  selectMetricHistoryState,
  MetricHistoryState.selectEntities,
);
export const selectActiveEquipmentTriggers = createSelector(
  selectActiveEquipmentIndustrialSensors,
  selectAllTriggers,
  (sensors, triggers) => {
    const activeEquipmentSensorIds = sensors.map(sensor => {
      return sensor.id;
    });
    return triggers.filter(trigger => {
      return activeEquipmentSensorIds.includes(trigger.sensorId);
    });
  },
);

/**
 * Utility Services
 */
export const selectUtilityServicesState = createSelector(
  selectCoreFeatureState,
  state => state.utilityServices,
);
export const selectAllUtilityServices = createSelector(
  selectUtilityServicesState,
  UtilityServicesState.selectAll,
);
export const selectAllUtilityServiceViews = createSelector(
  selectAllUtilityServices,
  selectIndieSensorEntities,
  selectLightEntities,
  selectScheduledUtilityRateViewsGroupedByUtilityService,
  (
    utilityServices,
    indieSensorEntities,
    lightEntities,
    scheduledUtilityRateViewsGroupedByUtilityService,
  ) => {
    return utilityServices.map(utilityService => {
      return toUtilityServiceViewModelFromInternal(
        utilityService,
        indieSensorEntities,
        lightEntities,
        scheduledUtilityRateViewsGroupedByUtilityService,
      );
    });
  },
);
export const selectAllUtilityServiceMainIds = createSelector(
  selectAllUtilityServices,
  utilityServices => {
    return utilityServices.reduce(
      (mainIds: Set<string>, utilityService: UtilityServiceInternalModel) => {
        utilityService.mainIds.forEach(mainId => mainIds.add(mainId));

        return mainIds;
      },
      new Set<string>(),
    );
  },
);

/**
 * Zones
 */
export const selectControlZonesState = createSelector(
  selectCoreFeatureState,
  state => state.controlZones,
);
export const selectZoneIds = createSelector(
  selectControlZonesState,
  ControlZonesState.selectIds,
);
export const selectZoneEntities = createSelector(
  selectControlZonesState,
  ControlZonesState.selectEntities,
);
export const selectAllZones = createSelector(
  selectControlZonesState,
  ControlZonesState.selectAll,
);
export const selectAllNonHiddenZones = createSelector(
  selectAllZones,
  (zones: ZoneInternalModel[]): ZoneInternalModel[] =>
    zones.filter(zone => !zone.id.startsWith('hidden_')),
);
export const selectTotalZones = createSelector(
  selectControlZonesState,
  ControlZonesState.selectTotal,
);
export const selectAllNonDLHZones = createSelector(
  selectAllNonHiddenZones,
  (zones: ZoneInternalModel[]) =>
    zones.filter(zone => zone.behaviorId !== BehaviorType.DLH),
);
export const selectAllNonAllZones = createSelector(
  selectAllZones,
  (zones: ZoneInternalModel[]) => zones.filter(zone => zone.id !== ZONE_ALL_ID),
);
export const selectActiveClimateZoneId = createSelector(
  selectRouterParams,
  params => params['activeClimateZoneId'] as string | undefined | null,
);
export const selectActiveClimateZone = createSelector(
  selectAllZones,
  selectActiveClimateZoneId,
  (zones, id) => {
    return zones.find(t => t.id === id);
  },
);
export const selectActiveZoneThermostatViews = createSelector(
  selectActiveClimateZone,
  selectAllThermostats,
  selectAllThermostatAlarms,
  (activeZone, thermostats, alarms) => {
    const activeZoneThermostats = thermostats.filter(thermostat =>
      activeZone?.deviceIds.includes(thermostat.id),
    );

    const numAlarms = (thermostatId: string) =>
      alarms.filter(alarm => alarm.thermostatId === thermostatId).length;

    return activeZoneThermostats.map(t => ({ totalAlarms: numAlarms(t.id), ...t }));
  },
);

/**
 * Light Zones
 */
export const selectLightZonesState = createSelector(
  selectCoreFeatureState,
  state => state.lightZones,
);

export const selectAllLightZones = createSelector(
  selectLightZonesState,
  LightZonesState.selectAll,
);

export const selectZoneSetLookupTableByLightId = createSelector(
  selectLightZonesState,
  LightZonesState.selectZoneSetLookupTableByLightId,
);

export const selectAllLightingZones = createSelector(selectAllNonHiddenZones, zones => {
  return zones.filter(zone => zone.application === ZoneApplication.LIGHTING);
});

export const selectActiveLightZoneId = createSelector(
  selectRouterParams,
  params => (params['lightZoneId'] || params['zoneId']) as string | undefined | null,
);
export const selectActiveLightingZone = createSelector(
  selectAllLightingZones,
  selectActiveLightZoneId,
  (zones, id) => {
    return zones.find(t => t.id === id);
  },
);

export const selectAllLightsByZone = createSelector(
  selectAllLightZones,
  selectLightEntities,
  (lightZones, lightEntities) => {
    return lightZones.reduce((lightsByZone, lightZone) => {
      const light = lightEntities[lightZone.lightId];
      if (!light) {
        return lightsByZone;
      }
      if (lightsByZone[lightZone.zoneId]) {
        lightsByZone[lightZone.zoneId].push(light);
      } else {
        lightsByZone[lightZone.zoneId] = [light];
      }
      return lightsByZone;
    }, {} as Record<LightZoneInternalModel['zoneId'], LightInternalModel[]>);
  },
);

export const selectAllLightsInActiveZone = createSelector(
  selectAllLightsByZone,
  selectActiveLightZoneId,
  (lightsByZone, zoneId) => {
    if (!zoneId) {
      return [];
    }
    return lightsByZone[zoneId] ? [...lightsByZone[zoneId]].sort(sortByName) : [];
  },
);

/**
 * Climate Zones
 */
export const selectAllClimateZones = createSelector(selectAllZones, zones =>
  zones.filter(zone => zone.application === ZoneApplication.CLIMATE),
);

/**
 * Custom Light View
 */
const isPositionedLightModel = (
  light: LightInternalModel,
): light is PositionedLightModel =>
  light.level != null &&
  light.floorPlanId != null &&
  light.floorPlanX != null &&
  light.floorPlanY != null;

export const selectAllLightViews = createSelector(
  selectAllLights,
  selectZoneSetLookupTableByLightId,
  selectAlarmLookupTableByControllerId,
  (lights, zoneSetTablesByLightId, alarmsTablesByControllerId) =>
    lights
      .filter(isPositionedLightModel)
      .map(light =>
        toLightViewModel(
          light,
          alarmsTablesByControllerId[light.controllerId] ?? [],
          zoneSetTablesByLightId,
        ),
      ),
);

/**
 * Custom Scheduled Scene Calendar View
 */
export const selectAllScheduledEventViews = createSelector(
  selectAllScheduledEvents,
  scheduledEvents =>
    scheduledEvents.map(scheduledEvent =>
      toScheduledEventViewModelFromInternal(scheduledEvent),
    ),
);

/**
 * Custom Scene Views
 */
export const selectSceneZoneBehaviorViews = createSelector(
  selectAllSceneZoneBehaviors,
  selectZoneEntities,
  (sceneZoneBehaviors, zoneEntities) =>
    sceneZoneBehaviors
      .filter(sceneZoneBehavior => Boolean(zoneEntities[sceneZoneBehavior.zoneId]))
      .map(
        (sceneZoneBehavior): SceneZoneBehaviorViewModel => ({
          ...sceneZoneBehavior,
          name: (<ZoneInternalModel>zoneEntities[sceneZoneBehavior.zoneId]).name,
        }),
      )
      .sort(sortByName),
);

export const selectSceneZoneBehaviorGroups = createSelector(
  selectSceneZoneBehaviorViews,
  sceneZoneBehaviors => {
    const byId = values(
      groupBy(sceneZoneBehaviors, sceneZoneBehavior => sceneZoneBehavior.behaviorId),
    );

    return byId.flatMap(sceneZoneBehaviors =>
      values(
        groupBy(
          sceneZoneBehaviors,
          sceneZoneBehavior => sceneZoneBehavior.behaviorParameters,
        ),
      ),
    );
  },
);

export const selectSceneZoneBehaviorViewsBySceneId = createSelector(
  selectSceneZoneBehaviorViews,
  sceneZoneBehaviors =>
    sceneZoneBehaviors.reduce((dict, sceneZoneBehavior) => {
      if (!dict[sceneZoneBehavior.sceneId]) {
        dict[sceneZoneBehavior.sceneId] = [sceneZoneBehavior];
      } else {
        dict[sceneZoneBehavior.sceneId].push(sceneZoneBehavior);
      }

      return dict;
    }, {} as Record<SceneInternalModel['id'], SceneZoneBehaviorViewModel[]>),
);

export const selectSceneViews = createSelector(
  selectAllScenes,
  selectSceneZoneBehaviorViewsBySceneId,
  selectApplyScenesStatusState,
  (
    scenes: SceneInternalModel[],
    szbsBySceneId: Record<SceneInternalModel['id'], SceneZoneBehaviorViewModel[]>,
    statuses: { [sceneId: string]: SceneStates },
  ): SceneViewModel[] =>
    scenes.map<SceneViewModel>(scene => ({
      ...scene,
      application: scene.application || SceneApplication.LIGHTING,
      state: statuses[scene.id] || 'ready',
      linkedBehaviorList: toLinkedBehaviorsFromSceneZoneBehaviors(
        szbsBySceneId[scene.id] || [],
      ),
      isHidden: scene.id.startsWith('hidden_'),
      get linkedZoneCount(): number {
        return this.linkedBehaviorList.reduce(
          (acc: number, item: LinkedBehavior) => acc + item.zoneList.length,
          0,
        );
      },
    })),
);

export const selectActiveClimateZoneSceneViews = createSelector(
  selectActiveClimateZone,
  selectSceneViews,
  (activeZone, sceneViews) =>
    sceneViews.filter(sceneView => {
      return sceneView.linkedBehaviorList.some(linkedBehavior =>
        linkedBehavior.zoneList.some(zone => activeZone && zone.id === activeZone.id),
      );
    }),
);

export const selectActiveLightZoneSceneViews = createSelector(
  selectActiveLightZoneId,
  selectSceneViews,
  (lightingZoneId, sceneViews) =>
    sceneViews.filter(sceneView => {
      return sceneView.linkedBehaviorList.some(linkedBehavior =>
        linkedBehavior.zoneList.some(zone => zone.id === lightingZoneId),
      );
    }),
);

export const selectActiveLightingZoneHasScenes = createSelector(
  selectActiveLightZoneSceneViews,
  sceneViews => sceneViews.length > 0,
);

export const selectActiveLightingZoneHasLights = createSelector(
  selectAllLightsInActiveZone,
  lights => lights.length > 0,
);

export const selectActiveLightingZoneIsDimmer = createSelector(
  selectActiveLightingZone,
  zone => zone?.behaviorId === BehaviorType.Dimmer,
);

export const selectActiveLightingZoneIsBasicColor = createSelector(
  selectActiveLightingZone,
  zone => zone?.behaviorId === BehaviorType.BasicColor,
);

/**
 * Custom Utility Selectors
 */
export const selectUsedSnapaddrs = createSelector(
  selectAllControllers,
  selectAllIndieSensors,
  (lightingControllers, indieSensors): string[] => {
    const lightingSnapaddrs = lightingControllers.map(controller => {
      return controller.snapaddr;
    });
    const senseSnapaddrs = indieSensors
      .map(indieSensor => {
        return indieSensor.snapaddr;
      })
      .filter((snapAddr): snapAddr is string => !!snapAddr);

    return lightingSnapaddrs.concat(senseSnapaddrs);
  },
);

/**
 * Custom Indie Sensor View Selectors
 */
export const selectIndieSensorViewModels = createSelector(
  selectAllIndieSensors,
  selectSiteTime,
  (allSensors, siteTime) => {
    return allSensors
      .filter(sensor => sensor.floorPlanId)
      .map(sensor => toIndieSensorMapViewModel(sensor, siteTime));
  },
);

export const selectIndieSensorViewModelsById = createSelector(
  selectIndieSensorViewModels,
  indieSensors =>
    indieSensors.reduce((lookupTable, indieSensor) => {
      lookupTable[indieSensor.id] = indieSensor;

      return lookupTable;
    }, {} as { [id: string]: IndieSensorMapViewModel }),
);

export const selectActiveIndieSensor = createSelector(
  selectAllIndieSensors,
  selectActiveIndieSensorId,
  selectSiteTime,
  (
    sensors: (IndieSensorInternalModel | Bridge485MetricInternalModel)[],
    id: string | undefined | null,
    siteTime: DateTime,
  ) => {
    if (!id) {
      return;
    }

    const sensor = sensors.find(sensor => sensor.id === id);

    if (!sensor) {
      return;
    }

    return toIndieSensorDetailsViewModel(sensor, siteTime);
  },
);

export const selectActiveIndieSensorSiteController = createSelector(
  selectActiveIndieSensor,
  selectSiteControllerEntities,
  (
    activeSensor: IndieSensorDetailsViewModel | undefined,
    siteControllers: Dictionary<SiteControllerInternalModel>,
  ) =>
    activeSensor?.state?.siteControllerId
      ? siteControllers[activeSensor.state.siteControllerId]
      : null,
);

export const selectIndieSensorDetailsViewModels = createSelector(
  selectAllIndieSensors,
  selectSiteTime,
  (allSensors, siteTime) => {
    return allSensors.map(sensor => toIndieSensorDetailsViewModel(sensor, siteTime));
  },
);

/**
 * @todo Jake Harris
 * Update this stuff during the model refactor. Should
 * these really all be optional? Required?
 * Is IndieSensorModel the right type to use here?
 */
export const toIndieSensorMapViewModel = (
  sensor: IndieSensorInternalModel,
  siteTime: DateTime,
): IndieSensorMapViewModel => {
  const indieSensorMVM: IndieSensorMapViewModel = {
    id: sensor.id,
    name: sensor.name,
    floorPlanX: sensor.floorPlanX!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    floorPlanY: sensor.floorPlanY!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    dataType: sensor.dataType,
    status: getIndieSensorStatus(sensor.state!, siteTime), // eslint-disable-line @typescript-eslint/no-non-null-assertion
    state: sensor.state,
  };

  if (sensor.snapaddr !== undefined) {
    indieSensorMVM.snapaddr = sensor.snapaddr;
  }

  return indieSensorMVM;
};

export const toIndieSensorDetailsViewModel = (
  sensor: IndieSensorInternalModel | Bridge485MetricInternalModel,
  siteTime: DateTime,
): IndieSensorDetailsViewModel | Bridge485SensorDetailsViewModel => {
  const indieSensorDVM: IndieSensorDetailsViewModel | Bridge485SensorDetailsViewModel = {
    id: sensor.id,
    name: sensor.name,
    dataType: sensor.dataType,
    status: getIndieSensorStatus(sensor.state!, siteTime), // eslint-disable-line @typescript-eslint/no-non-null-assertion
    state: sensor.state,
    hardwareType: sensor.hardwareType,
  };

  if (sensor.snapaddr !== undefined) {
    indieSensorDVM.snapaddr = sensor.snapaddr;
  }

  /**
   * The second condition here is logically redundant, but TypeScript
   * can't figure it out without help.
   */
  if (isBridge485Metric(sensor) && isBridge485Metric(indieSensorDVM)) {
    indieSensorDVM.conversion = sensor.conversion;
    indieSensorDVM.dataAddress = sensor.dataAddress;
    indieSensorDVM.dataFormat = sensor.dataFormat;
    indieSensorDVM.functionCode = sensor.functionCode;
    indieSensorDVM.source = sensor.source;
    indieSensorDVM.unitId = sensor.unitId;
  }

  return indieSensorDVM;
};

/**
 * Custom Alarm Count Selectors
 */
export const selectNumberOfAlarmedLights = createSelector(
  selectAlarmLookupTableByControllerId,
  selectPositionedLights,
  (alarmLookupTable, positionedLights) =>
    positionedLights.filter(light =>
      Object.keys(alarmLookupTable).includes(light.controllerId),
    ).length,
);

export const selectNumberOfAlarmedIndieSensors = createSelector(
  selectIndieSensorViewModels,
  indieSensors =>
    indieSensors.filter(indieSensor => isIndieSensorAlarmed(indieSensor)).length,
);

export const selectAlarmedThermostats = createSelector(
  selectAllThermostats,
  selectThermostatAlarmTypeByThermostatId,
  (allThermostats, thermostatAlarmTypesByThermostat) =>
    allThermostats.filter(thermostat => thermostatAlarmTypesByThermostat[thermostat.id]),
);

export const selectNumberOfAlarmedThermostats = createSelector(
  selectAlarmedThermostats,
  alarmedThermostats => alarmedThermostats.length,
);

export const selectTotalUnclearedAlarms = createSelector(
  selectAllThermostatAlarms,
  selectAllAlarms,
  (thermostatAlarms, otherAlarms) => {
    return otherAlarms.filter(alarm => !alarm.cleared).length + thermostatAlarms.length;
  },
);

export const selectAllSiteControllersForActiveSite = createSelector(
  selectAllSiteControllers,
  selectActiveSiteId,
  (siteControllers, siteId) =>
    siteControllers.filter(siteController => siteController.siteId === siteId),
);

export const selectActiveSequenceSceneViews = createSelector(
  selectAllActiveSequenceScenes,
  selectAllSequenceScenes,
  selectAllScenes,
  (activeSequenceScenes, sequenceScenes, staticScenes) =>
    orderBy(activeSequenceScenes, 'currentStepCompletionTime', 'asc').map(
      activeSequenceScene =>
        toActiveSequenceSceneViewModelFromInternal(
          activeSequenceScene,
          sequenceScenes,
          staticScenes,
        ),
    ),
);
